<template>
    <div>
        <SidebarMenu :titulo="tituloMenu" />
        <div class="vld-parent">
            <loading :active.syn="isLoading" :can-cancel="true"  :is-full-page="fullPage"></loading>
        </div>
        <BTableSimple responsive style="padding: 30px;">
            <BThead>
                <BTr>
                <BTh sticky-column>Usuario</BTh>
                <BTh variant="">Administrador</BTh>
                <BTh>Informes</BTh>
                <BTh>Control interno</BTh>
                <BTh>Corresponsal</BTh>
                <BTh>Caja</BTh>
                </BTr>
            </BThead>
            <BTbody>
                <BTr v-for="(item, index) in items" :key="index">
                    <BTh variant="danger" sticky-column>{{item.usuario}}</BTh>
                    <BTh >
                        <label><input type="checkbox" class="form-check-input" id="administrador" v-model="item.administrador"></label>
                    </BTh>
                    <BTh variant="danger">
                        <label><input type="checkbox" class="form-check-input" id="informes" v-model="item.informes"></label>
                    </BTh>
                    <BTh variant="danger">
                        <label><input type="checkbox" class="form-check-input" id="control_interno" v-model="item.control_interno"></label>
                    </BTh>
                    <BTh variant="danger">
                        <label><input type="checkbox" class="form-check-input" id="corresponsal" v-model="item.corresponsal"></label>
                    </BTh>
                    <BTh variant="danger">
                        <label><input type="checkbox" class="form-check-input" id="corresponsal" v-model="item.caja"></label>
                    </BTh>
                    
                </BTr>
            </BTbody>
 
        </BTableSimple>
        <div style="text-align: center;">
            <BButton variant="danger" @click="save_changes" >Guardar</BButton>
        </div>
    </div>
  </template>
  
<script>
  import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue'
  import backendRouter from  '@/components/BackendRouter/BackendRouter'
  import Loading from 'vue-loading-overlay';
  import axios from 'axios'
  import router from '@/router'
import { BButton } from 'bootstrap-vue-next';
  export default{
    data() {
        return {
            tituloMenu: "Permisos",
            isLoading: true,
            fullPage: true,
            items:[],
            cabecera:[
                {text:'Usuario', value:'usuario'},
                {text:'Administrador', value:'administrador_check'},
                {text:'Informes', value:'informes_check'},
                {text:'Control interno', value:'control_interno_check'},
                {text:'Gestion humana', value:'gestion_humana_check'},
                {text:'Contabilidad', value:'contabilidad_check'},
                {text:'Comisiones', value:'comisiones_check'},
                {text:'Soporte', value:'soporte_check'},
                {text:'Auditoria', value:'auditoria_check'},
                {text:'Comercial', value:'comercial_check'},
            ],
        }
    },
    methods:{
        get_permisos(){
            const path = backendRouter.data+'permissions'
            axios.post(path, {'jwt':this.$cookies.get('jwt')}).then((response)=>{
                this.items = response.data.usuarios
                this.isLoading = false
            })
            .catch((error)=>{
                this.$swal(error.response.data.detail)
            })
            
        },
        save_changes(){
            this.isLoading = true
            const path = backendRouter.data+'permissions-edit'
            axios.post(path, {'jwt':this.$cookies.get('jwt'), 'data':this.items}).then((response)=>{
                this.isLoading = false
                this.$swal('Guardado con exito')
            })
            .catch((error)=>{
                this.$swal(error.response.data.detail)
            })
        }
    },
    components: {
        SidebarMenu,
        Loading,
    },
    created(){
        this.get_permisos()
    },
  }
</script>
  
  <style scoped>
  
  </style>
  