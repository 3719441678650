<template>
  <div class="container mt-5">
    <div class="card shadow-sm">
      <div class="card-header bg-danger text-white">
        <h2 class="card-title">Políticas de Privacidad</h2>
      </div>
      <div class="card-body">
        <section>
          <h3 class="text-danger">1. Responsable del Tratamiento de Datos Personales</h3>
          <p>
            <strong>Razón social:</strong> TEAM COMUNICACIONES<br>
            <strong>NIT:</strong> 900.206.408-1<br>
            <strong>Dirección:</strong> Av. 33 #65c - 47, Medellín, Belén, Medellín.<br>
            <strong>Correo electrónico:</strong> soporte@teamcomunicaciones.com<br>
            <strong>Teléfono:</strong> (604) 6046679
          </p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">2. Información que Recopilamos</h3>
          <p>Recopilamos información personal de manera directa (a través de formularios, contratos o interacciones en nuestros puntos de atención) o indirecta (a través de herramientas digitales y plataformas). Los datos personales que tratamos incluyen, pero no se limitan a:</p>
          <ul>
            <li>Nombre completo.</li>
            <li>Tipo y número de documento de identidad.</li>
            <li>Información de contacto: dirección, correo electrónico y número de teléfono.</li>
            <li>Información contractual, como detalles de los servicios adquiridos.</li>
            <li>Datos de facturación y pago.</li>
            <li>Información socioeconómica (cuando sea necesaria para brindar ciertos servicios).</li>
          </ul>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">3. Finalidades del Tratamiento</h3>
          <p>Los datos personales serán tratados únicamente para las siguientes finalidades:</p>
          <ul>
            <li>Gestionar la relación comercial y contractual con los clientes.</li>
            <li>Ofrecer, prestar y realizar soporte técnico para los servicios móviles y de hogar de Claro.</li>
            <li>Procesar pagos, emitir facturas y manejar obligaciones contractuales.</li>
            <li>Informar sobre promociones, ofertas, campañas y novedades de los productos y servicios ofrecidos por Claro y TEAM COMUNICACIONES.</li>
            <li>Realizar estudios de mercado y encuestas para mejorar nuestros productos y servicios.</li>
            <li>Cumplir con obligaciones legales, regulatorias y contractuales.</li>
          </ul>
          <p>En caso de realizar un tratamiento diferente al indicado, se solicitará autorización expresa al titular de los datos personales.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">4. Derechos de los Titulares</h3>
          <p>De acuerdo con la normativa vigente en Colombia, los titulares de los datos personales tienen los siguientes derechos:</p>
          <ul>
            <li>Acceder a la información personal que hemos recopilado.</li>
            <li>Conocer, actualizar y rectificar sus datos.</li>
            <li>Solicitar la eliminación de sus datos personales cuando no sean necesarios o se haya superado la finalidad para la cual fueron recolectados.</li>
            <li>Revocar la autorización otorgada para el tratamiento de sus datos personales.</li>
            <li>Presentar quejas ante la Superintendencia de Industria y Comercio (SIC) en caso de considerar vulnerados sus derechos.</li>
          </ul>
          <p>Para ejercer estos derechos, puede comunicarse con nosotros a través del correo electrónico: <a href="mailto:soporte@teamcomunicaciones.com">soporte@teamcomunicaciones.com</a>.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">5. Transferencia y Transmisión de Datos</h3>
          <p>TEAM COMUNICACIONES podrá compartir los datos personales de sus titulares con Claro Colombia, en calidad de distribuidora oficial, para el cumplimiento de las finalidades relacionadas con la prestación de servicios. Asimismo, podrá compartir datos con aliados estratégicos, proveedores y autoridades competentes, siempre garantizando la protección de la información conforme a la legislación vigente.</p>
          <p>Los datos personales no serán vendidos, alquilados ni utilizados para fines no autorizados.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">6. Medidas de Seguridad</h3>
          <p>En TEAM COMUNICACIONES implementamos medidas técnicas, organizativas y de seguridad informática para garantizar la protección de los datos personales contra acceso no autorizado, pérdida, uso indebido, alteración o destrucción.</p>
          <p>No obstante, el titular es responsable de mantener la confidencialidad de sus credenciales de acceso a nuestros sistemas, en caso de que aplique.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">7. Conservación de los Datos</h3>
          <p>Los datos personales serán almacenados únicamente durante el tiempo necesario para cumplir con las finalidades del tratamiento o mientras exista una obligación legal o contractual que lo exija.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">8. Modificaciones a las Políticas de Privacidad</h3>
          <p>TEAM COMUNICACIONES se reserva el derecho de modificar estas políticas en cualquier momento. Las actualizaciones serán publicadas a través de los canales oficiales de la empresa.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">9. Contacto</h3>
          <p>Si tiene alguna duda o desea obtener más información sobre nuestras políticas de privacidad, puede contactarnos a través de:</p>
          <ul>
            <li>Correo electrónico: <a href="mailto:soporte@teamcomunicaciones.com">soporte@teamcomunicaciones.com</a></li>
            <li>Teléfono: (604) 6046679</li>
          </ul>
          <p>En TEAM COMUNICACIONES, su privacidad es nuestra prioridad.</p>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PoliticasPrivacidad',
};
</script>
<style scoped>
.container {
  max-width: 800px;
}

.card-header {
  background-color: #DF1115;
  color: #ffffff;
}

.card-title {
  margin: 0;
}

.text-danger {
  color: #DF1115 !important;
}

hr {
  border-top: 1px solid #DF1115;
}
</style>