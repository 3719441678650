<template>
  <div class="">
    <SidebarMenu :titulo="tituloMenu"/>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"></loading>
    </div>
    <div class="row">
      <div class=" m-3 p-3 col-5">
        <h3>{{ sucursal }}</h3>
        <BFormInput style="width: 180px;" type="date" v-model="fecha" @change="getData"/>
        <h2>Cajero: {{ total }}</h2>
        <h2>Consignaciones: {{ consignaciones }}</h2>
        <h2>Diferencia: {{ getDisponible(total, consignaciones) }}</h2>
        <BButton v-if="boton" variant="danger" class="" @click="showConsignacionModal = true" >Montar Consignación</BButton>
      </div>
      <div class=" m-3 p-3 col-5">
        <table v-if="detalleConsignaciones.length>0">
          <thead>
            <tr>
              <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
              <th>Banco</th>
              <th>Valor</th> 
            </tr>
          </thead>
          <tbody>
            <tr v-for="(detalle, index) in detalleConsignaciones" :key="index">
              <td>{{ detalle.banco}}</td>
              <td>{{ detalle.valor }}</td>
              <td>{{ detalle.columna3 }}</td>
              <td>
                <button @click="mostrarInformacion(detalle)">
                  <IBiEye />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div v-if="showConsignacionModal" class="modal d-flex justify-content-center align-items-center">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Montar Consignación</h2>
            <button type="button" class="close" @click="showConsignacionModal = false">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="uploadConsignacion" enctype="multipart/form-data">
              <div class="form-group">
                <label for="valor">Valor a Consignar:</label>
                <input type="number" class="form-control" v-model="consignacion.valor" required>
              </div>
              <div class="form-group">
                <label for="banco">Banco:</label>
                <select class="form-control" v-model="consignacion.banco" required>
                  <option value="Corresponsal Banco de Bogota">Corresponsal Banco de Bogota</option>
                  <option value="Saitemp">Saitemp</option>
                  <option value="Proveedores">Proveedores</option>
                  <option value="Otros bancos">Otros bancos</option>
                  <option value="Obligaciones financieras">Obligaciones financieras</option>
                  <option value="Corresponsal Team (70981) Bancolombia">Corresponsal Team (70981) Bancolombia</option>
                  <option value="Corresponsal Elena (70988) Bancolombia">Corresponsal Elena (70988) Bancolombia</option>
                  <option value="Prestamos a Team">Prestamos a Team</option>
                  <option value="Volantes Sub">Volantes Sub</option>
                  <option value="Volantes Agaval">Volantes Agaval</option>
                  <option value="Volantes venta grabada">Volantes venta grabada</option>
                  <option value="Reclamaciones">Reclamaciones</option>
                  <option value="Venta doble proposito">Venta doble proposito</option>
                  <option value="Otros">Otros</option>
                </select>
              </div>

              <div v-if="consignacion.banco === 'Otros bancos'" class="form-group">
                <label for="banco">Banco detalle:</label>
                <select class="form-control" v-model="consignacion.bancoDetalle" required>
                  <option value="Bancolombia">Bancolombia</option>
                  <option value="Bogotá">Bogotá</option>
                  <option value="Caja social Team">Caja social Team</option>
                  <option value="Av Villas">Av Villas</option>
                  <option value="Davivienda">Davivienda</option>
                  <option value="Agrario">Agrario</option>
                </select>
              </div>

              <div v-if="consignacion.banco === 'Proveedores' || consignacion.banco === 'Obligaciones financieras' || consignacion.banco === 'Otros'" class="form-group">
                <label for="proveedor">Detalle banco:</label>
                <input type="text" class="form-control" v-model="consignacion.proveedor" required>
              </div>

              <div class="form-group">
                <label for="fechaConsignacion">Fecha de Consignación:</label>
                <input type="date" class="form-control" v-model="consignacion.fechaConsignacion" required>
              </div>

              <div class="form-group">
                <label for="detalle">Detalle:</label>
                <textarea class="form-control" v-model="consignacion.detalle"></textarea>
              </div>

              <div class="form-group">
                <label for="imagen">Adjuntar Imagen:</label>
                <input type="file" class="form-control-file" @change="onFileChange" required>
              </div>

              <div class="text-center mt-4">
                <BButton type="submit" variant="danger">Guardar</BButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showImagenModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showImagenModal = false">&times;</span>
        <img :src="`data:${imageContent};base64,${imageBlob}`" alt="Imagen subida" width="300" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue';
import Loading from 'vue-loading-overlay';
import backendRouter from '@/components/BackendRouter/BackendRouter';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tituloMenu: 'Cuadre caja',
      fullPage: true,
      isLoading: false,
      boton: false,
      fecha: false,
      total: '$0',
      consignaciones: '$0',
      detalleConsignaciones: [],
      showConsignacionModal: false,
      showImagenModal: false,
      image: null,
      imageBlob: null,
      imageContent: null,
      sucursal: '',
      consignacion: {
        valor: '',
        banco: '',
        bancoDetalle: '',
        proveedor: '',
        fechaConsignacion: '',
        detalle: ''
      }
    };
  },
  methods: {
    getDisponible(caja, consignaciones){
      let cajaNumericoStr = caja.replace(/[^0-9.-]+/g, '');
      let cajaNumerico = parseFloat(cajaNumericoStr);
      let consignacionesNumericoStr = consignaciones.replace(/[^0-9.-]+/g, '');
      let consignacionesNumerico = parseFloat(consignacionesNumericoStr);
      let resultado = cajaNumerico - consignacionesNumerico;
      return resultado.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    getData() {
      this.isLoading = true;
      if (this.fecha != '') {
        const path = backendRouter.data + 'select-datos-corresponsal-cajero';
        const data = {
          fecha: this.fecha,
          jwt: this.$cookies.get('jwt'),
        };
        axios.post(path, data).then((response) => {
          this.total = response.data.total;
          this.isLoading = false;
          this.sucursal = response.data.sucursal
          this.boton = true
          this.getDataConsignaciones()
        }).catch((error) => {
          this.consolidado = false;
          this.isLoading = false;
        });
      } else {
        this.consolidado = false;
        this.isLoading = false;
      }
    },
    getDataConsignaciones(){
      this.isLoading = true;
      console.log(this.fecha);
      if (this.fecha != '') {
        const path = backendRouter.data + 'select-consignaciones-corresponsal-cajero';
        const data = {
          fecha: this.fecha,
          sucursal: this.sucursal,
        };
        axios.post(path, data).then((response) => {
          this.consignaciones = response.data.total;
          this.detalleConsignaciones =response.data.detalles;
          this.isLoading = false;
        }).catch((error) => {
          this.consolidado = false;
          this.isLoading = false;
        });
      } else {
        this.consolidado = false;
        this.isLoading = false;
      }
    },
    mostrarInformacion(detalle){
      const path = backendRouter.data + 'get-imagen-corresponsal'
      const data = detalle
      this.isLoading = true
      axios.post(path, detalle).then((response) =>{
        this.imageBlob = response.data.image;
        this.imageContent = response.data.content_type;
        this.showImagenModal = true;
        this.isLoading = false
      });
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.image = file;
    },
    async uploadConsignacion(){
      const formData = new FormData();
      formData.append('image', this.image);
      formData.append('data',  JSON.stringify(this.consignacion));
      formData.append('fecha', this.fecha);
      formData.append('jwt', this.$cookies.get('jwt'));
      formData.append('sucursal', this.sucursal);
      const path = backendRouter.data+'consignacion-corresponsal'
      this.isLoading = true;
      try {
        const response = await axios.post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          this.showConsignacionModal = false
          this.getData()
          this.$swal('Consignacion guardada con exito')
        }
      } catch (error) {
        console.error('Error al subir la imagen:', error.response.data);
      }
    },
    montarConsignacion() {
      const data = {
          'data': this.consignacion,
          'fecha': this.fecha,
          'jwt':this.$cookies.get('jwt'),
          'sucursal': this.sucursal
      }
      const path = backendRouter.data+'consignacion-corresponsal'
      axios.post(path, data).then((response)=>{
          this.showConsignacionModal = false
          this.getData()
          this.$swal('Consignacion guardada con exito')
      })
      .catch((error)=>{
          this.$swal(error.response.data.detail)
      })
    }
  },
  components: {
    SidebarMenu,
    Loading,
  }
};
</script>

<style scoped>
.table-wrapper {
  width: 90%;
  overflow-x: auto;
}

.tarjeta {
  padding: 30px;
  width: 350px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.form-group {
  margin-bottom: 15px;
}
</style>
