
<template>
    <div class="">
        <form >
            <div>
                <SidebarMenu :titulo="tituloMenu"/>
                <div class="vld-parent">
                    <loading :active.syn="isLoading" :can-cancel="true"  :is-full-page="fullPage"></loading>
                </div>

                <div v-if="create==true" class="table-wrapper m-3 p-3">
                    <div style="margin-top: 30px;">
                        <input class="" id="archivoExcel" type="file" @change="subirExcel()"> 
                    </div>
                    <div style="height: 20px;"></div>
                    <div>
                        <BButton variant="danger" v-if="items.length > 0" color="#E11419" dark class="mb-2" @click=accion_boton >{{titulo_boton}}</BButton>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th v-for="j in titulos">{{ j }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in items">
                                <td v-for="j in row">{{ j }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
        
    </div>
  
    </template>

<script>

import Loading from 'vue-loading-overlay';
import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue'
import readXlsFile from 'read-excel-file'
import backendRouter from  '@/components/BackendRouter/BackendRouter'
import axios from 'axios';
export default{
    data(){
        return{
            tituloMenu: 'corresponsal',
            create: true,
            isLoading: false,
            fullPage: true,
            accion_boton: '',
            titulo_boton: 'guardar',
            items:[],
        }
    },
    methods:{
        subirExcel(){
                this.titulo_boton = 'GUARDAR'
                this.accion_boton = this.guardar
                const input = document.getElementById("archivoExcel");
                readXlsFile(input.files[0]).then((rows) =>{
                    this.titulos = rows[0]
                    this.items = rows.slice(1)
                })
            },
        guardar(){
            this.isLoading = true
            const path = backendRouter.data+'guardar-datos-corresponsal'
            const data = {
                'cabecera': this.titulos, 
                'items': this.items,
            }
            axios.post(path, data).then((response)=>{
                this.$swal('Datos guardados con éxito')
                this.isLoading = false
            }).catch((error)=>{
                this.$swal(error.response.data.detail)
                this.isLoading = false
            })
        }
    },
    components: {
        SidebarMenu,
        Loading
    },
}

</script>

<style scoped>
    .table-wrapper {
        width: 90%;
        overflow-x: auto;
        height: 500px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        position: sticky;
        top: 0;
        background-color: #f2f2f2;
        z-index: 1;
    }

    .btn-danger {
        background-color: #E11419;
        border-color: #E11419;
    }

</style>
