<template>
    <div>
        <SidebarMenu :titulo="tituloMenu"/>
        <div class="p-5">
            <div class="row">
                <div class="col-8">
                    <select class="card" id="precios" v-model="precio" @change="select_price">
                        <option v-for="precio in lista_precios" :key="precio.id" :value="precio.id">{{ precio.nombre }}</option>
                    </select>
                </div>
                <BButton variant="danger" v-if="excel" class="mb-2 col-1" @click=descargar >EXCEL</BButton>
            </div>
            <div class="col-md-12">
                <input v-model="filtro" placeholder="Filtrar"></input>
                <table v-if="items.length > 0" class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in itemsFiltrados" :key="index">
                            <td v-for="field in fields" :key="field.key">{{ item[field.key] }}</td>
                            <td>
                                <button @click="historico(item)">
                                    <IBiEye />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
    
            </div>
        </div>
    </div>
</template>

<script>
  import axios from 'axios'
  import router from '@/router'
  import backendRouter from  '@/components/BackendRouter/BackendRouter'
  import SidebarMenu from '@/components/Intranet/SidebarMenu/SidebarMenu.vue';
  import Loading from 'vue-loading-overlay';
  import * as XLSX from 'xlsx';
  export default{
    data(){
        return{
            tituloMenu: 'Precios',
            excel: false,
            filtro: '',
            data:[],
            items:[],
            precio: '',
            lista_precios : [],
            fields:[],
            isLoading: false,
            fullPage: true,   
        }
    },
    methods:{
        descargar(){         
            const data = this.items;
            const fileName = 'download.xlsx';
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data)
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
            XLSX.writeFile(workbook, fileName)
        },
        historico(item) {
            this.items = []
            const path = backendRouter.data+'lista-productos-prepago-equipo'
            const data = {
                'precio':this.precio,
                'equipo': item.equipo
            }
            axios.post(path, data).then((response)=>{
                this.items = response.data.data;
                this.generateTableFields(false);
            })
            .catch((error)=>{
                this.$swal(error.response.data.detail)
            })
        },
        get_data(){
            
            const path = backendRouter.data+'lista-productos-prepago'
            axios.put(path, {'jwt':this.$cookies.get('jwt')}).then((response)=>{
                this.lista_precios = response.data.data
            })
            .catch((error)=>{
                router.push('/login')
                this.$swal(error.response.data.detail)
            })
        },
        select_price(){
            console.log(this.precio)
            this.items = []
            const path = backendRouter.data+'lista-productos-prepago'
            const data = {
                'data':this.data,
                'precio':this.precio
            }
            axios.post(path, data).then((response)=>{
                this.items = response.data.data;
                this.generateTableFields(true);
                this.excel = true
            })
            .catch((error)=>{
                this.$swal(error.response.data.detail)
            })
        },
        generateTableFields(boton) {
            this.fields = Object.keys(this.items[0] || {}).map(key => {
                return { key: key, label: key };
            });
            if (boton){
                this.fields.push({ key: 'botones', label: 'Acciones' });
            }
        }
    },
    components: {
        SidebarMenu,
        Loading,
        
    },
    created(){
        this.get_data()
        
    },
    computed:{
        itemsFiltrados() {
            return this.items.filter((item) => {
                return item.equipo.toLowerCase().includes(this.filtro.toLowerCase());
            });
        },
    },
    mounted() {
    }
  }
</script>
  
<style scoped>
  
</style>
  