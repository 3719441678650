<template>
  <div class="container mt-5">
    <div class="card shadow-sm">
      <div class="card-header bg-danger text-white">
        <h2 class="card-title">Tratamiento de Datos Personales</h2>
      </div>
      <div class="card-body">
        <section>
          <h3 class="text-danger">1. Recolección de Datos Personales</h3>
          <p>En TEAM COMUNICACIONES, recopilamos datos personales de manera transparente, previa autorización del titular, y únicamente para las finalidades autorizadas. La recolección puede realizarse a través de:</p>
          <ul>
            <li>Formularios físicos o digitales.</li>
            <li>Interacciones en puntos de atención al cliente.</li>
            <li>Contratos, llamadas telefónicas, correos y medios electrónicos.</li>
            <li>Navegación en nuestras plataformas digitales, cumpliendo con la política de cookies (si aplica).</li>
          </ul>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">2. Uso de los Datos Personales</h3>
          <p>El uso de los datos personales recolectados estará limitado a las siguientes finalidades, las cuales han sido informadas previamente a los titulares:</p>
          <ul>
            <li>Prestar los servicios contratados por nuestros clientes, en especial, los servicios móviles y de hogar de Claro.</li>
            <li>Gestionar la relación contractual, incluyendo la facturación, soporte técnico y atención al cliente.</li>
            <li>Realizar actividades de marketing, como promociones y campañas publicitarias, siempre con autorización previa del titular.</li>
            <li>Cumplir con normativas legales y regulatorias aplicables al sector de telecomunicaciones.</li>
            <li>Los datos también podrán ser usados para finalidades estadísticas y de mejora continua, asegurando su anonimización en estos casos.</li>
          </ul>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">3. Almacenamiento y Conservación</h3>
          <p>Los datos personales serán almacenados en nuestras bases de datos seguras, utilizando herramientas tecnológicas que garantizan la protección contra acceso no autorizado, alteraciones, pérdida o destrucción.</p>
          <p><strong>Tiempo de conservación:</strong> Los datos serán conservados mientras exista una relación contractual con el titular, o mientras sea necesario para cumplir obligaciones legales. Una vez cumplida la finalidad, los datos serán eliminados o anonimizados, salvo que exista una obligación legal que requiera su conservación.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">4. Compartición de Datos Personales</h3>
          <p>TEAM COMUNICACIONES podrá compartir datos personales con las siguientes entidades, siempre bajo estrictas medidas de seguridad y confidencialidad:</p>
          <ul>
            <li>Claro Colombia: Para garantizar la prestación y soporte de los servicios móviles y de hogar.</li>
            <li>Aliados estratégicos y proveedores: Para apoyar la operación comercial, únicamente en los casos necesarios para cumplir las finalidades descritas.</li>
            <li>Autoridades competentes: En cumplimiento de órdenes judiciales, administrativas o legales.</li>
          </ul>
          <p>En ningún caso los datos personales serán vendidos, alquilados o compartidos para fines distintos a los autorizados por el titular.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">5. Transferencia Internacional de Datos</h3>
          <p>En caso de que sea necesario transferir datos personales a terceros ubicados fuera de Colombia (por ejemplo, para proveedores de tecnología en la nube), nos aseguraremos de que estos cumplan con estándares adecuados de protección de datos, tal como lo exige la normativa colombiana.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">6. Derechos del Titular y Atención de Solicitudes</h3>
          <p>Los titulares de los datos personales tienen derecho a:</p>
          <ul>
            <li>Conocer: Solicitar información sobre el uso de sus datos personales.</li>
            <li>Actualizar y rectificar: Corregir datos inexactos o desactualizados.</li>
            <li>Revocar la autorización: Solicitar la eliminación de sus datos personales, siempre que no exista una obligación legal o contractual que impida su eliminación.</li>
            <li>Presentar consultas y reclamos: Realizar consultas sobre el tratamiento de datos o presentar reclamos relacionados.</li>
          </ul>
          <p>Para ejercer estos derechos, el titular podrá enviar una solicitud por escrito a través de los siguientes canales:</p>
          <ul>
            <li>Correo electrónico: <a href="mailto:soporte@teamcomunicaciones.com">soporte@teamcomunicaciones.com</a></li>
            <li>Teléfono de atención al cliente: (604) 6046679</li>
            <li>Dirección física: Av. 33 #65c - 47, Medellín, Belén, Medellín.</li>
          </ul>
          <p>Las consultas serán respondidas en un plazo máximo de 10 días hábiles, y los reclamos en un plazo máximo de 15 días hábiles, según lo establece la normativa.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">7. Autorización para el Tratamiento de Datos</h3>
          <p>Antes de recolectar cualquier dato personal, TEAM COMUNICACIONES obtendrá la autorización expresa del titular, salvo en los casos en los que la ley permita su tratamiento sin autorización previa (por ejemplo, datos públicos). Esta autorización podrá ser otorgada por medios físicos, electrónicos o digitales.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">8. Revocación de la Autorización</h3>
          <p>Los titulares podrán revocar su autorización para el tratamiento de datos en cualquier momento, siempre que no exista una obligación legal o contractual que requiera el uso continuo de los datos. La revocación puede realizarse a través de los mismos canales de atención mencionados anteriormente.</p>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">9. Medidas de Seguridad</h3>
          <p>Implementamos las siguientes medidas para garantizar la seguridad de los datos personales:</p>
          <ul>
            <li>Control de acceso restringido a las bases de datos.</li>
            <li>Uso de herramientas de encriptación y protección contra amenazas cibernéticas.</li>
            <li>Capacitación continua a nuestro personal sobre protección de datos.</li>
          </ul>
        </section>
        <hr>
        <section>
          <h3 class="text-danger">10. Responsabilidad y Contacto</h3>
          <p>TEAM COMUNICACIONES es responsable del cumplimiento de estas políticas y está comprometida con la protección de los datos personales de todos los titulares. Si tienes alguna inquietud, puedes comunicarte con nuestro Oficial de Protección de Datos a través de:</p>
          <ul>
            <li>Correo electrónico: <a href="mailto:soporte@teamcomunicaciones.com">soporte@teamcomunicaciones.com</a></li>
            <li>Teléfono: (604) 6046679</li>
            <li>Dirección física: Av. 33 #65c - 47, Medellín, Belén, Medellín.</li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TratamientoDatos',
};

</script>
<style scoped>
.container {
  max-width: 800px;
}

.card-header {
  background-color: #DF1115;
  color: #ffffff;
}

.card-title {
  margin: 0;
}

.text-danger {
  color: #DF1115 !important;
}

hr {
  border-top: 1px solid #DF1115;
}
</style>